<template>
    <section>
        <v-data-table
            :headers="ticket_tbl"
            :items="tickets"
            :items-per-page="5"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="elevation-1 text-center"
        >
            <template v-slot:item.id="{ item }">
                <v-btn text class="fw400" color="primary" @click="openDialog(item)">
                    EDU-{{item.id}}
                </v-btn>
            </template>
            <template v-slot:item.concern_category="{ item }">
                <section class="text-capitalize">
                    {{ item.concern_category }}
                </section>
            </template>
            <template v-slot:item.status="{ item }">
                <v-icon color="gray" small class="mr-2">
                    mdi-circle
                </v-icon>
                PENDING
            </template>
        </v-data-table>
        <TicketDialog v-if="selectedTicket" :dialog="dialog" :ticket="selectedTicket" @close="closeDialog"/>
    </section>
</template>
<script>
import TicketDialog from './TicketDialog.vue';
import { ticket_tbl } from '../../../constants/tblheaders/ticket';
export default {
    props: ["tickets"],
    data: () => ({
        ticket_tbl,
        dialog: false,
        selectedTicket: null,
        sortBy: 'id',
        sortDesc: true
    }),
    methods: {
        openDialog(item){
            this.dialog = true
            this.selectedTicket = item
        },
        closeDialog(){
            this.dialog=false
        }
    },
    components: { TicketDialog }
}
</script>